<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateIssueDialog :dialogCreateIssue="dialogCreateIssue" :StoreObj="StoreObj" @clicked="dialogCreateIssueEmit" />

    <ChangeIssuesStatusDialog
      :pendingTab="pendingTab"
      v-if="dialogChangeIssuesStatus == true"
      :dialogChangeIssuesStatus="dialogChangeIssuesStatus"
      @clicked="dialogChangeIssuesStatusEmit"
      :StoreObj="StoreObj"
    />
    <ShareIssueListDialog :dialogShareIssue="dialogShareIssue" @clicked="dialogShareIssueEmit" />
    <BulkIssueAssignDialog
      :selected_array="selected_array"
      :dialogBulkIssueAssign="dialogBulkIssueAssign"
      @clicked="dialogBulkIssueAssignEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="4">
          <v-toolbar dense class="elevation-0">
            <v-tabs v-show="only_owner_condition == false" v-model="pendingTab" @change="AtTabChangeEvent()">
              <v-tab class="text-capitalize">Pending On Me</v-tab>
              <v-tab class="text-capitalize" @click="filterItems()">Reported By Me</v-tab>
            </v-tabs>
            <v-tabs v-show="only_owner_condition == true" v-model="pendingTab" @change="AtTabChangeEvent()">
              <v-tab class="text-capitalize">Pending On Me</v-tab>
              <v-tab class="text-capitalize">Reported By Me</v-tab>
            </v-tabs>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="5">
          <v-card-actions class="px-0 pb-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              outlined
              class="FontSize borderRadius maxWidthLarge field_height field_label_size mt-2 mr-2"
              append-icon="mdi-magnify"
              label="Search"
              v-model="search"
            ></v-text-field>
            <v-select
              dense
              outlined
              v-if="pendingTab == 0"
              class="FontSize borderRadius field_height field_label_size mt-2 mr-2"
              label="Issue Status"
              :items="issue_statusItems"
              v-model="issue_status"
              item-text="text"
              item-value="value"
            ></v-select>
          </v-card-actions>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3">
          <v-card-actions class="px-0 pb-0">
            <v-select
              dense
              outlined
              class="FontSize borderRadius field_height field_label_size mt-2"
              :class="issue_status == 'ASSIGNED' ? 'maxWidthSmall' : ''"
              label="Sort By"
              :items="sort_by_items"
              v-model="sort_by"
              item-text="text"
              item-value="value"
            ></v-select>
            <v-menu offset-y v-if="GetAllPendingIssuesList.length != 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :loading="loading"
                  small
                  class="mr-2 text-capitalize mt-n5 ml-2"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>mdi-file-excel</v-icon>
                  Excel
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, index) in items" :key="index" @click="checkItemMenu(item.value)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-else
              small
              color="primary"
              :disabled="GetAllIssuesDetailsList.length == 0"
              class="text-capitalize mt-n5 ml-2"
              >Excel</v-btn
            >
            <v-btn
              small
              class="mt-n5"
              :disabled="selected_array == 0"
              color="primary"
              @click="dialogBulkIssueAssign = true"
              >Assign</v-btn
            >
          </v-card-actions>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12">
          <v-data-table
            dense
            :fixed-header="true"
            :height="windowHeight"
            class="elevation-0"
            :headers="
              pendingTab == 0 ? issuesHeader : issuesHeader.filter((header) => header.value != 'issue_reported_by')
            "
            :footer-props="{
              'items-per-page-options': [50, 100, 250, 500, 1000],
            }"
            :items-per-page="50"
            :items="pendingTab == 0 ? GetAllPendingIssuesList : GetAllIssuesOfUserList"
            :search="search"
            @click:row="changeIssueStatus"
            :no-data-text="noDataText"
            :show-select="issue_status == 'ASSIGNED' && pendingTab == 0 && checkCondtionBool() == true ? true : false"
          >
            <template v-slot:[`header.data-table-select`]>
              <v-checkbox
                dense
                class="px-0 mx-0 pb-0 mb-n3"
                v-model="is_selected"
                @click.stop="pushToArray(undefined, 'MULTIPLE')"
              ></v-checkbox>
            </template>
            <template v-slot:[`item.data-table-select`]="{ item }">
              <v-checkbox
                dense
                @click.stop="pushToArray(item, 'SINGLE')"
                class="mx-0 px-0 pb-0 mb-0"
                v-if="item.is_reassigned == false"
                v-model="item.is_selected"
              ></v-checkbox>
            </template>
            <template v-slot:[`item.issue_id`]="{ item }">
              <div class="FontSize">
                <v-icon
                  small
                  color="primary"
                  v-text="item.issue_type == 'BUG' ? 'mdi-bug' : 'mdi-receipt-text-plus'"
                ></v-icon>
                {{ item.issue_id }}
              </div>
            </template>
            <template v-slot:[`item.issue_type`]="{ item }">
              <div class="FontSize">{{ item.issue_type }}</div>
            </template>
            <template v-slot:[`item.issue_reported_by`]="{ item }">
              <div class="FontSize">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-text="
                        item.is_externally_reported_issue == true ? 'mdi-book-arrow-right-outline' : 'mdi-file-document'
                      "
                      small
                      color="primary"
                      class="mr-2"
                    ></v-icon>
                  </template>
                  <span v-text="item.is_externally_reported_issue == true ? 'External Form' : 'Internal Form'"></span>
                </v-tooltip>
                {{ item.issue_reported_by
                }}<span class="font-weight-bold" v-if="$store.getters.get_user_email == item.issue_reported_by"
                  >(Me)</span
                >
              </div>
            </template>
            <template v-slot:[`item.Ageing`]="{ item }">
              <div class="FontSize">{{ checkTime(item.issue_reported_on) }}</div>
              <div class="fontSizeSmall font-weight-bold">Last Updated {{ checkTime(item.last_updated_on) }}</div>
            </template>
            <template v-slot:[`item.issue_reported_platform`]="{ item }">
              <div class="FontSize">
                {{ item.issue_reported_platform ? item.issue_reported_platform.replaceAll("_", " ") : "-" }}
              </div>
            </template>
            <template v-slot:[`item.issue_module_name`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="FontSize" v-bind="attrs" v-on="on">{{
                    item.issue_module_name
                      ? item.issue_module_name.length > 20
                        ? `${item.issue_module_name.slice(0, 20)}..`
                        : `${item.issue_module_name}`
                      : "-"
                  }}</span>
                </template>
                <span>{{ item.issue_module_name ? item.issue_module_name : "-" }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.issue_severity`]="{ item }">
              <div
                class="FontSize"
                :class="
                  item.issue_severity == 'HIGH'
                    ? 'red--text'
                    : item.issue_severity == 'MEDIUM'
                    ? 'deepYellowClass'
                    : 'blue--text'
                "
              >
                {{ item.issue_severity ? item.issue_severity.replaceAll("_", " ") : "-" }}
                <span class="black--text font-weight-bold" v-if="item.is_reopen == true">(RE-OPENED)</span>
              </div>
            </template>
            <template v-slot:[`item.issue_assigned_to`]="{ item }">
              <div class="FontSize">
                {{ item.issue_assigned_to ? item.issue_assigned_to : "-" }}
                <span class="font-weight-bold" v-if="$store.getters.get_user_email == item.issue_assigned_to"
                  >(Me)</span
                >
              </div>
            </template>
            <template v-slot:[`item.issue_status`]="{ item }">
              <div class="FontSize">{{ item.issue_status.replaceAll("_", " ") }}</div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { HasActionAccess } from "@/mixins/HasActionAccess.js";
import { GetIssueStatusFilteredByUser } from "@/mixins/GetIssueStatusFilteredByUser.js";
import { GetAllIssuesDetails } from "@/mixins/GetAllIssuesDetails.js";
import { GetAllIssuesOfUser } from "@/mixins/GetAllIssuesOfUser.js";
import { GetAllPendingIssues } from "@/mixins/GetAllPendingIssues.js";
import { GetDownloadedExcel } from "@/mixins/GetDownloadedExcel.js";
import CreateIssueDialog from "@/components/Issues/Dialogs/CreateIssueDialog.vue";
import ChangeIssuesStatusDialog from "@/components/Extras/ChangeIssuesStatusDialog.vue";
import ShareIssueListDialog from "@/components/Issues/Dialogs/ShareIssueListDialog.vue";
import BulkIssueAssignDialog from "@/components/Approvals/Dialogs/BulkIssueAssignDialog.vue";
import { GetEachProjectDetails } from "@/mixins/GetEachProjectDetails.js";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  components: {
    ChangeIssuesStatusDialog,
    BulkIssueAssignDialog,
    ShareIssueListDialog,
    CreateIssueDialog,
    Overlay,
  },
  mixins: [
    HasActionAccess,
    GetAllIssuesDetails,
    GetIssueStatusFilteredByUser,
    GetDownloadedExcel,
    GetEachProjectDetails,
    GetAllPendingIssues,
    GetAllIssuesOfUser,
  ],
  data: () => ({
    search: "",
    ListIssuesOfUserAcrossAllProjectsArray: [],
    pendingTab: 0,
    overlay: false,
    loading: false,
    windowHeight: 0,
    dialogShareIssue: false,
    dialogCreateIssue: false,
    dialogDeleteIssue: false,
    dialogBulkIssueAssign: false,
    dialogChangeIssuesStatus: false,
    StoreObj: {},
    issuesHeader: [
      {
        text: "Issue ID",
        value: "issue_id",
      },
      {
        text: "Title",
        value: "issue_module_name",
      },
      {
        text: "Ageing",
        value: "Ageing",
      },
      {
        text: "Reported By",
        value: "issue_reported_by",
      },
      {
        text: "Sub-System",
        value: "issue_reported_platform",
      },
      {
        text: "Pending On",
        value: "issue_assigned_to",
      },
      {
        text: "Severity",
        value: "issue_severity",
      },
      {
        text: "Status",
        value: "issue_status",
      },
    ],
    issuesItems: [],
    issue_status: "",
    issue_statusItems: [],
    items: [
      { title: "Download", value: "Download" },
      { title: "Share", value: "Share" },
    ],
    roles: [],
    sort_by_items: [
      { text: "Most Recent", value: "MOST_RECENT" },
      { text: "Most Aged", value: "MOST_AGED" },
      { text: "Last Updated", value: "LAST_UPDATED_ON" },
    ],
    sort_by: "MOST_RECENT",
    selected_array: [],
    is_selected: false,
    only_owner_condition: false,
    count: 0,
  }),
  watch: {
    // pendingTab(val) {
    //   if (val == 1) {
    //     console.log("nannnnnn", this.GetAllIssuesOfUserList);
    //   }
    // },
      search(){
        
      },
    sort_by(value) {
      if (value) {
        this.filterItems();
      }
    },

    issue_status() {
      this.$forceUpdate();

      if (this.pendingTab == 0) this.GetAllPendingIssuesMethod(this.issue_status);
      else this.GetAllIssuesOfUserMethod(this.issue_status);
    },
    "$store.getters.get_refresh"(value) {
      if (value == true) {
        if (this.pendingTab == 0) this.GetAllPendingIssuesMethod(this.issue_status);
        else this.GetAllIssuesOfUserMethod(this.issue_status);
      }
    },
  },
  async mounted() {
    this.only_owner_condition = false;
    this.roles = this.$store.getters.get_current_project_details.roles;
    this.windowHeight = window.innerHeight - 190;

    if (this.roles.length == 1 && this.roles[0] == "OWNER") {
      this.issue_statusItems = ["ALL", "UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
      this.issue_status = "ALL";
      this.AtTabChangeEvent();
      this.only_owner_condition = true;
      this.pendingTab = 1;
      console.log("1", this.issue_status);
    } else if (this.roles.length == 1 && this.roles[0] == "OBSERVER") {
      this.issue_statusItems = ["ALL", "UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
      this.issue_status = "ALL";
      this.AtTabChangeEvent();
      this.only_owner_condition = true;
      this.pendingTab = 1;
      console.log("1", this.issue_status);
    } else if (
      this.roles.length == 2 &&
      this.roles.includes("OWNER") == true &&
      this.roles.includes("APPROVER") == true
    ) {
      this.issue_statusItems = ["ALL", "UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
      this.issue_status = "ALL";
      this.AtTabChangeEvent();
      this.only_owner_condition = true;
      this.pendingTab = 1;
      console.log("2", this.issue_status);
    } else if (this.roles.length == 2 && this.roles[0] == "OBSERVER") {
      this.issue_statusItems = ["ALL", "UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
      this.issue_status = "ALL";
      this.AtTabChangeEvent();
      this.only_owner_condition = true;
      this.pendingTab = 1;
      console.log("1", this.issue_status);
    } else {
      this.only_owner_condition = false;
      this.AtTabChangeEvent();
      console.log("3");
    }
    this.$forceUpdate();
  },
  methods: {
    async AtTabChangeEvent() {
      this.selected_array = [];
      if (this.pendingTab == 0) {
        this.issue_statusItems = await this.issueStatusFilterMethod();
        this.issue_status = this.issue_statusItems[0].value;
        this.GetAllPendingIssuesMethod(this.issue_status);
      } else if (this.pendingTab == 1) {
        this.issue_statusItems = ["ALL", "UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
        this.issue_status = "ALL";
        this.GetAllIssuesOfUserMethod(this.issue_status);
      }
      this.$forceUpdate();
    },
    checkCondtionBool() {
      if (this.GetAllPendingIssuesList.filter((issue) => issue.is_reassigned == false).length != 0) return true;
      else return false;
    },
    dialogBulkIssueAssignEmit(Toggle) {
      this.dialogBulkIssueAssign = false;
      this.selected_array = [];
      if (Toggle == 2) {
        this.GetAllPendingIssuesMethod(this.issue_status);
      }
    },
    pushToArray(item, type) {
      if (type == "SINGLE") {
        this.selected_array = this.GetAllPendingIssuesList.filter((issue) => issue.is_selected == true);
      } else if (type == "MULTIPLE") {
        if (this.is_selected == true) {
          this.GetAllPendingIssuesList = this.GetAllPendingIssuesList.map((issue) => {
            let object = issue;
            object.is_selected = issue.is_reassigned == false ? true : false;
            return object;
          });
          this.selected_array = this.GetAllPendingIssuesList.filter((issue) => issue.is_selected == true);
        } else {
          this.GetAllPendingIssuesList = this.GetAllPendingIssuesList.map((issue) => {
            let object = issue;
            object.is_selected = false;
            return object;
          });
          this.selected_array = [];
        }
      }
      this.$forceUpdate();
      console.log("Check selected_array", this.selected_array);
    },

    filterItems() {
      var self = this;
      console.log("tab==1");
      switch (self.sort_by) {
        case "MOST_RECENT":
          if (self.pendingTab == 0) {
            self.GetAllPendingIssuesList = self.GetAllPendingIssuesList.sort(function (x, y) {
              return new Date(y.issue_reported_on).getTime() - new Date(x.issue_reported_on).getTime();
            });
          } else if (self.pendingTab == 1) {
            self.GetAllIssuesOfUserList = self.GetAllIssuesOfUserList.sort(function (x, y) {
              return new Date(y.issue_reported_on).getTime() - new Date(x.issue_reported_on).getTime();
            });
          }
          break;
        case "MOST_AGED":
          if (self.pendingTab == 0) {
            self.GetAllPendingIssuesList = self.GetAllPendingIssuesList.sort(function (x, y) {
              return new Date(x.issue_reported_on).getTime() - new Date(y.issue_reported_on).getTime();
            });
          } else if (self.pendingTab == 1) {
            self.GetAllIssuesOfUserList = self.GetAllIssuesOfUserList.sort(function (x, y) {
              return new Date(x.issue_reported_on).getTime() - new Date(y.issue_reported_on).getTime();
            });
          }
          break;
        case "LAST_UPDATED_ON":
          if (self.pendingTab == 0) {
            self.GetAllPendingIssuesList = self.GetAllPendingIssuesList.sort(function (x, y) {
              return new Date(y.last_updated_on).getTime() - new Date(x.last_updated_on).getTime();
            });
          } else if (self.pendingTab == 1) {
            self.GetAllIssuesOfUserList = self.GetAllIssuesOfUserList.sort(function (x, y) {
              return new Date(y.last_updated_on).getTime() - new Date(x.last_updated_on).getTime();
            });
          }
          break;
      }
    },
    issueStatusFilterMethod() {
      let issue_statusItems = [];
      if (this.roles.includes("TEST_LEAD")) {
        issue_statusItems.push(
          {
            text: "UNACKNOWLEDGED",
            value: "UNACKNOWLEDGED",
            priority: 1,
          },
          {
            text: "FIXED",
            value: "FIXED",
          }
        );
      }
      //    let issue_statusItems = [];
      if (this.roles.includes("TESTER" && "APPROVER")) {
        issue_statusItems.push(
          {
            text: "UNACKNOWLEDGED",
            value: "UNACKNOWLEDGED",
            priority: 1,
          },
          {
            text: "FIXED",
            value: "FIXED",
          }
        );
      }

      if (this.roles.includes("TESTER")) {
        issue_statusItems.push({
          text: "FIXED",
          value: "FIXED",
          priority: 2,
        });
      }
      if (this.roles.includes("DEV_LEAD")) {
        issue_statusItems.push({
          text: "ASSIGNED",
          value: "ASSIGNED",
          priority: 3,
        });
      }
      if (this.roles.includes("DEVELOPER")) {
        issue_statusItems.push({
          text: "ASSIGNED",
          value: "ASSIGNED",
          priority: 3,
        });
      }
      return Array.from(new Set(issue_statusItems.map(JSON.stringify)))
        .map(JSON.parse)
        .sort((a, b) => {
          return a.priority - b.priority;
        });
    },
    checkTime(second) {
      var seconds = (new Date().getTime() - new Date(second).getTime()) / 1000;
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor(seconds % 60);

      var dDisplay = d > 0 ? d + (d == 1 ? " Day Ago" : " Days Ago") : "";
      var hDisplay = h > 0 ? h + (h == 1 ? " Hour Ago" : " Hours Ago") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " Minute Ago" : " Minutes Ago") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " Second Ago" : " Seconds Ago") : "";
      if (dDisplay != "") return dDisplay;
      else if (hDisplay != "") return hDisplay;
      else if (mDisplay != "") return mDisplay;
      else if (sDisplay) return sDisplay;
    },
    dialogShareIssueEmit() {
      this.dialogShareIssue = false;
    },
    checkItemMenu(action) {
      switch (action) {
        case "Download":
          this.GetDownloadedExcelMethod(action);
          break;
        case "Share":
          this.dialogShareIssue = true;
          break;
      }
    },

    dialogChangeIssuesStatusEmit(Toggle) {
      this.dialogChangeIssuesStatus = false;
      if (Toggle == 2) {
        if (this.pendingTab == 0) {
          this.GetAllPendingIssuesMethod(this.issue_status);
          this.GetEachProjectDetailsMethod();
          console.log("project details is called");
        } else if (this.pendingTab == 1) {
          this.GetAllIssuesOfUserMethod(this.issue_status);
          console.log("project details is called");
        }
      }
  
    },
    changeIssueStatus(item) {
      this.StoreObj = item;
      this.dialogChangeIssuesStatus = true;
    },
    dialogDeleteIssueEmit() {
      this.dialogDeleteIssue = false;
    },
    checkItem(item) {
      this.StoreObj = item;
      this.dialogDeleteIssue = true;
    },
    dialogCreateIssueEmit(Toggle) {
      this.dialogCreateIssue = false;
      if (Toggle == 2) {
        this.GetAllPendingIssuesMethod(this.issue_status);
        this.GetEachProjectDetailsMethod();
      }
    },
  },
};
</script>
