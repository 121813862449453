<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogBulkIssueAssign" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div>Bulk Assign</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogBulkIssueAssignEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-form ref="form">
            <v-select
              denseF
              outlined
              label="Select User"
              class="FontSize field_height field_label_size"
              v-model="assigned_to_email_id"
              :items="GetAllUserDetailsList.filter((user) => user.user_email_id != $store.getters.get_user_email)"
              item-text="user_email_id"
              item-value="user_email_id"
              :rules="[(v) => !!v || 'Required']"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius text-capitalize"
            color="primary"
            outlined
            @click="dialogBulkIssueAssignEmit(1)"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn
            small
            class="borderRadius text-capitalize mr-2"
            color="primary"
            :loading="loading"
            @click="validateMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllUsersDetails } from "@/mixins/GetAllUsersDetails.js";
import { bulkAssignIssuesToDeveloper } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogBulkIssueAssign: Boolean,
    selected_array: Array,
  },
  components: {
    Snackbar,
  },
  mixins: [GetAllUsersDetails],
  data: () => ({
    count: 0,
    Toggle: 0,
    loading: false,
    assigned_to_email_id: "",
    SnackBarComponent: {},
  }),
  mounted() {
    this.GetAllUsersDetailsMethod("DEVELOPER");
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.bulkIssueAssignMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Email ID",
        };
      }
    },
    async bulkIssueAssignMethod() {
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.$store.getters.get_current_project_details.project_id,
        assigned_to_email_id: this.assigned_to_email_id,
        issue_ids: this.selected_array.map((issue) => issue.issue_id),
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(bulkAssignIssuesToDeveloper, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.BulkAssignIssuesToDeveloper);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.dialogBulkIssueAssignEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.log("Check The Error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogBulkIssueAssignEmit(Toggle) {
      if (this.$refs.form) this.$refs.form.reset();
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
