import { listIssuesOfUserBasedOnIssueStatus } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllPendingIssues = {
  data() {
    return {
      noDataText: "",
      GetAllPendingIssuesList: [],
      overlay: false,
    };
  },
  methods: {
    async GetAllPendingIssuesMethod(issue_status) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listIssuesOfUserBasedOnIssueStatus, {
            input: {
              project_id: this.$store.getters.get_current_project_details.project_id,
              user_email_id: this.$store.getters.get_user_email,
              issue_status,
            },
          })
        );
        this.GetAllPendingIssuesList = JSON.parse(result.data.ListIssuesOfUserBasedOnIssueStatus);
        this.filterItems();
        if (this.GetAllPendingIssuesList.length == 0) {
          this.noDataText = `No ${issue_status} Issues Found `;
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllPendingIssuesList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
