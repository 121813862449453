import { listIssuesOfUser } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllIssuesOfUser = {
  data() {
    return {
      noDataText: "",
      GetAllIssuesOfUserList: [],
      overlay: false,
      pendingTab: 0,
      sort_by: "MOST_RECENT",
    };
  },
  methods: {
    async GetAllIssuesOfUserMethod(issue_status) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listIssuesOfUser, {
            input: {
              project_id: this.$store.getters.get_current_project_details.project_id,
              user_email_id: this.$store.getters.get_user_email,
              issue_status,
              action_status: "REPORTED_BY",
            },
          })
        );
        this.GetAllIssuesOfUserList = JSON.parse(result.data.ListIssuesOfUser).Items;
        console.log("Check GetAllIssuesOfUserList", this.GetAllIssuesOfUserList);
        if (this.pendingTab == 1) {
          if (this.sort_by == "MOST_RECENT") {
            this.GetAllIssuesOfUserList = this.GetAllIssuesOfUserList.sort(function (x, y) {
              return new Date(y.issue_reported_on).getTime() - new Date(x.issue_reported_on).getTime();
            });
          }
        }
        this.$store.commit("SET_REFRESH", false);
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllIssuesOfUserList = [];
        this.$store.commit("SET_REFRESH", false);
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
